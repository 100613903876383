@import './quantity-input.desktop';
@import './quantity-input-custom-label';
@import './quantity-selector.desktop';
@import './quantity-native';
@import '../../../../styles/mixins';

.#{$pdp-prefix}-buybox__quantity {
  color: color(color_gray_dark);
  margin-top: $andes-spacing-12;

  &__custom-options {
    width: 100%;
    position: relative;

    div[data-tippy-root] {
      width: 100%;
    }

    .#{$pdp-prefix}-buybox__quantity__available {
      margin-left: $andes-spacing-4;
      font-size: $font-size-16;
    }

    .#{$pdp-prefix}-buybox__quantity__selected {
      display: flex;

      &__label:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    color: color(color_gray_dark);

    &--last {
      font-weight: $font-weight-semibold;
    }
  }

  &__title-error {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    color: color(color_red);
    margin-right: $andes-spacing-4;

    &--last {
      font-weight: $font-weight-semibold;
    }
  }

  &__selected {
    margin-left: $andes-spacing-4;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    color: color(color_gray_dark);
    white-space: nowrap;

    &__label {
      margin-left: $andes-spacing-4;
    }
  }

  &__selected-error {
    margin-left: 2px;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    color: color(color_red);
    white-space: nowrap;

    & span {
      color: color(color_red);
    }
  }

  &__error {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    color: color(color_red);
    white-space: nowrap;
  }

  &__chevron {
    margin-left: $andes-spacing-4;
    padding-left: $andes-spacing-4;
    padding-right: $andes-spacing-4;
    vertical-align: bottom;

    .ui-pdp-icon--chevron {
      height: 11px;
      width: $andes-spacing-8;
    }
  }

  .#{$pdp-prefix}-icon--disabled {
    stroke: $andes-gray-250;

    path {
      stroke: $andes-gray-250 !important;
    }
  }

  &__available {
    @include truncate(auto, unset);

    color: color(color_gray_middle);
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-left: $andes-spacing-4;
  }

  &__trigger-bulksale {
    height: fit-content;
    cursor: pointer;
    padding: 0;

    &:hover,
    :focus,
    :active {
      background-color: transparent;
    }

    .andes-button__content {
      display: grid;
      max-width: 275px;
      grid-auto-flow: column;
    }

    &--options {
      .andes-button__content {
        max-width: 320px;

        .#{$pdp-prefix}-buybox__quantity__selected__label {
          float: none;
          display: inline;
        }
      }
    }
  }

  &__trigger-custom-options {
    width: 100%;
    border-radius: $border-radius-6;
    padding: 14px 10px;

    &#quantity-selector {
      border: 1px solid $andes-gray-250;
    }

    &--active {
      &#quantity-selector {
        border: 2px solid $andes-blue-500;
      }
    }

    &--error {
      &#quantity-selector {
        border: 2px solid $andes-red-500;
      }
    }

    .andes-button__content {
      justify-content: flex-start;
    }

    .#{$pdp-prefix}-buybox__quantity__chevron {
      justify-content: flex-end;
      display: flex;
      flex: 1;
      padding-left: 0;
    }
  }

  &__trigger {
    cursor: pointer;
    padding: 0;
    height: auto;
    line-height: 1;
    border: 0;

    &:hover,
    :focus,
    :active {
      background-color: transparent;
    }

    .andes-button__content {
      display: grid;
      max-width: 275px;
      grid-auto-flow: column;
    }

    &--options {
      .andes-button__content {
        max-width: 320px;

        .#{$pdp-prefix}-buybox__quantity__selected__label {
          float: none;
          display: inline;
        }
      }
    }
  }

  &__messages {
    line-height: 18px;

    &__message {
      @include money-amount-spacing;

      display: flex;
      margin: 4px;

      .ui-pdp-price__part {
        line-height: inherit;

        .price-tag-cents {
          line-height: inherit;
        }
      }

      &:empty {
        display: none;
      }
    }
  }

  &:has(p) {
    margin-top: $andes-spacing-24;
  }
}

.ui-pdp {
  .ui-pdp-buybox__quantity__custom-options {
    .ui-pdp-buybox__quantity__selected {
      max-width: 148px;
    }
  }
}
